/* eslint-disable */
import { ref, watch, computed } from "@vue/composition-api";
export default function useauditsList() {
  const refauditListTable = ref(null);

  const resolveUserStatusVariant = (status) => {
    if (status === "Created") return "success";
    if (status === "Changed") return "primary";
    if (status === "Deleted") return "danger";
    return "secondary";
  };

  const perPage = ref(10);
  const totalaudits = ref(0);
  const currentPage = ref(1);

  const dataMeta = computed(() => {
    const localItemsCount = refauditListTable.value
      ? refauditListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalaudits.value,
    };
  });

  return {
    perPage,
    currentPage,
    totalaudits,
    dataMeta,
    refauditListTable,
    resolveUserStatusVariant,
  };
}
